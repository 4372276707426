import {Home} from '../components/home';

export function HomeContainer() {

    return (
        <>
            { localStorage.getItem('user')
                ? window.location = '/portal'
                : <Home />
            }
        </>
    );
}

