import {Login} from '../components/login';

export function LoginContainer() {

    return (
        <>
            { localStorage.getItem('account')
                ? window.location = '/portal'
                : <Login />
            }
        </>
    );
}

