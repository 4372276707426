import {GetClue} from '../components/get_clue';

export function GetClueContainer() {


    return (
        <>
            {
                localStorage.getItem('user') ? <GetClue/> : window.location.replace('/')
            }
        </>
    );
}

