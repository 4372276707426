import {useState} from "react";

export function Register({setVerifyEmailSent}) {

    const [email, setEmail] = useState('');
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: '#ff5252',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        }
    };

    function handleChange(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (name === 'first') {
            setFirst(value);
        } else if (name === 'last') {
            setLast(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        } else if (name === 'password') {
            setPassword(value);
        } else {
            console.log('' + name + ' is not a valid input name');
        }
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/user/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                first: first,
                last: last,
                email: email,
                password: password,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error creating account')
        } else {
            window.location = '/login';
        }
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (password !== confirmPassword) {
            errorMessages.push('Passwords do not match');
        }
        if (!first || !last || !email || !password || !confirmPassword) {
            errorMessages.push('All fields are mandatory');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    return (
    <div className="App">
        <header className="App-header">
            <h1>Register new account</h1>
            <div className="registerForm">
                {errorMessage ?
                    <p style={styles.errorMessage}>{errorMessage}</p>
                    : null}
                <input type="text" name="first" placeholder="first name" onChange={handleChange}/><br />
                <input type="text" name="last" placeholder="last name" onChange={handleChange}/><br />
                <input type="email" name="email" placeholder="email" onChange={handleChange}/><br />
                <input type="password" name="password" placeholder="password" onChange={handleChange}/><br />
                <input type="password" name="confirmPassword" placeholder="confirm password" onChange={handleChange}/><br />
                <button style={styles.button} onClick={handleSubmit}>submit</button>
            </div>
        </header>
    </div>
    );
}
