
export function RegisterSent() {

    return (
    <div className="App">
        <header className="App-header">
            <h1>Email Verification Sent</h1>
            <p>Please check your email and follow directions to verify your account</p>
        </header>
    </div>
    );

}
