import {Game} from '../components/game';

export function GameContainer() {


    return (
        <>
            {
                localStorage.getItem('user') ? <Game /> : window.location.replace('/')
            }
        </>
    );
}

