import React, {useState, useEffect, useRef} from "react";
import LoadingIcons from 'react-loading-icons'
import { HiPuzzlePiece } from "react-icons/hi2";
import {CreateClue} from './modals/create_clue';
import {CreateClueOrigin} from './modals/create_clue_origin';
import {CreateClueCheckpoint} from './modals/create_clue_checkpoint';
import {UpdateRole} from './modals/update_role';
import {AssignKiller} from './modals/assign_killer';
import {AssignVictim} from './modals/assign_victim';

export function AdminClues({gameInfo, account}) {

    const [adminInfo, setAdminInfo]= React.useState(false);
    const [users, setUsers]= React.useState(false);
    const [players, setPlayers]= React.useState(false);
    const [rolesObject, setRolesObject]= React.useState(false);
    const [rolesArray, setRolesArray]= React.useState(false);
    const [updateRole, setUpdateRole]= React.useState(false);
    const [clueOriginId, setClueOriginId]= React.useState(false);
    const [cluesBody, setCluesBody]= React.useState(false);
    const [isCreateClueModalOpen, setIsCreateClueModalOpen] = React.useState(false);
    const [isCreateClueOriginModalOpen, setIsCreateClueOriginModalOpen] = React.useState(false);
    const [isCreateClueCheckpointModalOpen, setIsCreateClueCheckpointModalOpen] = React.useState(false);
    const [isAssignKillerModalOpen, setIsAssignKillerModalOpen] = React.useState(false);
    const [isAssignVictimModalOpen, setIsAssignVictimModalOpen] = React.useState(false);
    const [isUpdateRoleModalOpen, setIsUpdateRoleModalOpen] = React.useState(false);
    const adminRolesFetchedRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        secondaryButton: {
            backgroundColor: 'green',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '300px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        textArea: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '150px',
            padding: '0 12px',
            width: '300px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        gamesDiv: {
            backgroundColor: '#CCC',
            width: '100%',
            color: '#000',
        },
        icon: {
            fontSize: '80px',
        },
        userIcon: {
            fontSize: '50px',
        },
        adminIcon: {
            color: 'black',
            cursor: 'pointer',
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '140px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
            backgroundColor: 'white',
        },
        adminIconRole: {
            color: 'black',
            cursor: 'pointer',
            backgroundColor: 'lightgreen',
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
        },
        label: {
            fontSize: '14px',
            color: '#333',
            display: 'block',
            marginBottom: '4px',
            fontWeight: 'bold',
        },
    };

    useEffect(() => {
        if (adminRolesFetchedRef.current) return;
        adminRolesFetchedRef.current = true;
        if (!adminInfo) {
            getAdminInfo();
        }
    }, []);

    const getAdminInfo = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/admin/clues', {
            method: 'POST',
            body: JSON.stringify({
                account: account,
                game_id: gameInfo.uuid,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.status !== 200 || data.success !== true) {
            window.location = '/portal';
        } else {
            setCluesBody(getCluesJSX(data.response.clue_origins, data.response.clues, data.response.clue_checkpoints));
        }
    }

    function getRolesArray(users_response, players_response, roles_response) {
        if (Object.keys(roles_response).length === 0) {
            return {};
        }
        var roleKiller = gameInfo.killer;
        var roleVictim = gameInfo.victim;
        var rolesArray = {}
        for (var role_response in roles_response) {
            rolesArray[roles_response[role_response].id] = roles_response[role_response].name
        }
        return rolesArray;
    }

    function getCluesJSX(clue_origins_response, clues_response, clue_checkpoints_response) {
        if (Object.keys(clue_origins_response).length === 0) {
            return false;
        }
        return (<div style={{display: 'flex', textAlign: 'center'}}>
            {
                Object.keys(clue_origins_response)?.map((key,i)=>{
                    return (<div>
                        <button style={styles.secondaryButton} id={clue_origins_response[key].id} onClick={openCreateClueModal}>Create Step</button>
                        <button style={styles.secondaryButton} id={clue_origins_response[key].id} onClick={openCreateClueCheckpointModal}>Create Checkpoint</button>
                        <br />
                        <div style={styles.adminIcon} id={clue_origins_response[key].id} onClick={openEditClueOriginModal}>
                            {clue_origins_response[key].name}
                            <br /><HiPuzzlePiece style={styles.userIcon}/>
                        </div>
                            {
                                Object.keys(clues_response)?.map((j, k)=>{
                                    console.log(clues_response[j]);
                                    if (clues_response[j].clue_origin_id === clue_origins_response[key].id) {
                                        return (<div>
                                            <button id={clue_origins_response[key].id}>clue - {clues_response[j].name}</button>
                                            </div>);
                                    }
                                    console.log('we got here');
                                    return null;
                                })
                            }{
                                Object.keys(clue_checkpoints_response)?.map((l, m)=>{
                                    if (clue_checkpoints_response[l].clue_origin_id === clue_origins_response[key].id) {
                                        return (<div>
                                            <button style={{background: 'red'}} id={clue_origins_response[key].id}>clue - {clue_checkpoints_response[l].name}</button>
                                            </div>);
                                    }
                                    console.log('we got here');
                                    return null;
                                })
                            }
                    </div>
                    );
                })
            }
            <br />
        </div>);
    }

    function openCreateClueOriginModal() {
        setIsCreateClueOriginModalOpen(true);
    }

    function closeCreateClueOriginModal() {
        setIsCreateClueOriginModalOpen(false);
    }

    function openCreateClueModal(e) {
        setClueOriginId(e.target.id);
        setIsCreateClueModalOpen(true);
    }

    function closeCreateClueModal() {
        setIsCreateClueModalOpen(false);
    }

    function openCreateClueCheckpointModal(e) {
        setClueOriginId(e.target.id);
        setIsCreateClueCheckpointModalOpen(true);
    }

    function closeCreateClueCheckpointModal() {
        setIsCreateClueCheckpointModalOpen(false);
    }

    function openEditClueOriginModal() {
        setIsCreateClueCheckpointModalOpen(true);
    }

    function closeEditClueOriginModal() {
        setIsCreateClueCheckpointModalOpen(false);
    }

    function handleEditRole(e) {
        setUpdateRole(e.target.id);
        console.log(e.target.id);
        setIsUpdateRoleModalOpen(true);
    }

    return (
    <div>
        <h2>Game Clues</h2>
        <div style={{display: 'inline-flex'}}>
        <button style={styles.secondaryButton} onClick={openCreateClueOriginModal}>Create Clue Origin</button><br /><br />
        </div>
        { cluesBody ? cluesBody : (<p>No Clues</p>) }
        { isCreateClueModalOpen
            ? <CreateClue isModalOpen={isCreateClueModalOpen} setIsModalOpen={setIsCreateClueModalOpen} gameInfo={gameInfo} clueId={clueOriginId} styles={styles} />
            : null
        }
        { isCreateClueOriginModalOpen
            ? <CreateClueOrigin isModalOpen={isCreateClueOriginModalOpen} setIsModalOpen={setIsCreateClueOriginModalOpen} gameInfo={gameInfo} roles={rolesArray} styles={styles} />
            : null
        }
        { isCreateClueCheckpointModalOpen
            ? <CreateClueCheckpoint isModalOpen={isCreateClueCheckpointModalOpen} setIsModalOpen={setIsCreateClueCheckpointModalOpen} gameInfo={gameInfo} clueId={clueOriginId} styles={styles} />
            : null
        }
    </div>
    );
}
