import {JoinGame} from '../components/join_game';

export function JoinGameContainer() {


    return (
        <>
            {
                localStorage.getItem('user') ? <JoinGame/> : window.location.replace('/')
            }
        </>
    );
}

