import React, {useState, useRef, useEffect} from "react";
import Modal from 'react-modal';
import {AdminUsers} from './admin_users';
import {
  useParams,
} from "react-router-dom";

export function GetClue() {

    const [account, setAccount] = useState(localStorage.getItem('account'));
    const [errorMessage, setErrorMessage] = useState('');
    const [clueQuestion, setClueQuestion] = useState('');
    const [clueAnswer, setClueAnswer] = useState('');
    const [clueId, setClueId] = useState('');
    const [noClue, setNoClue] = useState(false);
    const { gameUuid, clueUuid } = useParams();
    console.log(gameUuid);
    console.log(clueUuid);
    const joinGameRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
    };

    useEffect(() => {
        if (joinGameRef.current) return;
        joinGameRef.current = true;
        getClueQuestion();
    }, []);

    const getClueQuestion = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/get-clue-question', {
            method: 'POST',
            body: JSON.stringify({
                account: account,
                game_id: gameUuid,
                clue_id: clueUuid,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (data.success !== true) {
            if (data.response === 'You already have this clue, go find another') {
                setErrorMessage(data.response);
                setNoClue(true);
            } else {
                window.location = '/game/' + gameUuid;
            }
            //window.location = '/portal';
        } else {
            setClueQuestion(data.response.role_question);
            setClueId(data.response.role_uuid);
        }
    };

    function handleClueAnswer(e) {
        setClueAnswer(e.target.value)
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!clueAnswer) {
            errorMessages.push('Please enter an answer');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/submit-clue-answer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                clue_answer: clueAnswer,
                clue_id: clueId,
                clue_uuid: clueUuid,
                account: localStorage.getItem('account'),
                game_id: gameUuid,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Incorrect');
        } else {
            console.log('go to clues!');
        }
    }


    return (
    <div className="App">
        <header className="App-header">
            <div>
            { !noClue
                ? (<><h1>{clueQuestion}</h1>
                <label htmlFor='clueAnswer' >Answer: </label><br />
                <input style={styles.textInput} type="text" onChange={handleClueAnswer} name="clueAnswer" id="clueAnswer" /><br />
                <button style={styles.button} onClick={handleSubmit}>Submit</button></>)
                : null }
            </div>
            { errorMessage
                ? <p style={styles.errorMessage}>{errorMessage}</p>
                : null }
        </header>
    </div>
    );
}
