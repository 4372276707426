import React, {useState} from "react";
import Modal from 'react-modal';
import { CgTranscript } from "react-icons/cg";

export function CreateClue({isModalOpen, setIsModalOpen, gameInfo, setRolesBody, clueId, styles, rolesBody}) {

    const [createClueName, setCreateClueName] = useState('');
    const [createRoleAlibi, setCreateRoleAlibi] = useState('');
    const [createClueValue, setCreateClueValue] = useState('');
    const [createRoleAnswer, setCreateRoleAnswer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function closeModal() {
        setIsModalOpen(false);
    }

    function handleClueName(e) {
        setCreateClueName(e.target.value)
    }
    function handleClueValue(e) {
        setCreateClueValue(e.target.value)
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!createClueName) {
            errorMessages.push('Please enter a name for your new clue');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/game/create-clue', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: createClueName,
                clue: createClueValue,
                account: localStorage.getItem('account'),
                game_id: gameInfo.uuid,
                clue_origin: clueId,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error creating clue');
        } else {
           setIsModalOpen(false);
        }
    }

    return (
    <div className="App">
        <Modal
            isOpen={isModalOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal"
        >
            <button style={styles.close} onClick={closeModal}>x</button>
            <div style={styles.center}>
            <h2 style={styles.center}>Create Clue</h2>
            <br />
            <label htmlFor='createClueName' style={styles.label}>Name: </label>
            <input style={styles.textInput} type="text" onChange={handleClueName} name="createClueName" id="createClueName" /><br />
            <br />
            <label htmlFor='createClueValue' style={styles.label}>Clue: </label>
            <textarea style={styles.textArea} type="texxtarea" onChange={handleClueValue} name="createClueValue" id="createClueVale" ></textarea><br />
            <br />
            <button style={styles.button} onClick={handleSubmit}>Create</button>

            { errorMessage ? (<p style={styles.errorMessage}>{errorMessage}</p>) : null }
            </div>
      </Modal>
    </div>
    );
}
