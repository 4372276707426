import React, {useState} from "react";
import Modal from 'react-modal';

export function CreateGame({isModalOpen, setIsModalOpen, styles}) {

    const [createGameName, setCreateGameName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function closeModal() {
        setIsModalOpen(false);
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'createGameName') {
            setCreateGameName(value);
        }
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!createGameName) {
            errorMessages.push('Please enter a name for your new game');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/game/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: createGameName,
                account: localStorage.getItem('account'),
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error logging in')
        } else {
            window.location = '/game/' + data.response.game_id;
        }
    }

    return (
    <div className="App">
        <Modal
            isOpen={isModalOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal"
        >
            <button style={styles.close} onClick={closeModal}>x</button>
            <div style={styles.center}>
            <h2 style={styles.center}>Create a game</h2>
            <p>Enter the game name to begin</p>
            <input style={styles.textInput} type="text" onChange={handleChange} name="createGameName" />
            <button style={styles.button} onClick={handleSubmit}>Create</button>
            </div>
      </Modal>
    </div>
    );
}
