import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {HomeContainer} from './containers/home'
import {RegisterContainer} from './containers/register'
import {LoginContainer} from './containers/login'
import {PortalContainer} from './containers/portal'
import {AdminContainer} from './containers/admin'
import {GameContainer} from './containers/game'
import {JoinGameContainer} from './containers/join_game'
import {GetClueContainer} from './containers/get_clue'
import {PhotoContainer} from './containers/photo'

export default function App() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<HomeContainer />} />
            <Route path="/login" element={<LoginContainer />} />
            <Route path="/register" element={<RegisterContainer />} />
            <Route path="/portal" element={<PortalContainer />} />
            <Route path="/admin/:gameUuid" element={<AdminContainer />} />
            <Route path="/game/:gameUuid" element={<GameContainer />} />
            <Route path="/join-game/:gameUuid" element={<JoinGameContainer />} />
            <Route path="/get-clue/:gameUuid/:clueUuid" element={<GetClueContainer />} />
            <Route path="/photo" element={<PhotoContainer />} />
        </Routes>
    </Router>
  );
}

function Home() {
    return (
    <div className="App">
        <header className="App-header">
            <h1>Death at a Dinner</h1>
            <p>
                bring murder mystery games to your home
            </p>
            <div className="row">
                <a href="/login">
                <button>Log in</button>
                </a>
                &nbsp;
                <a href="/register">
                <button>Register</button>
                </a>
            </div>
        </header>
    </div>
    );
}


