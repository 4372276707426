import {useState} from "react";
import {Register} from '../components/register';
import {RegisterSent} from '../components/register_sent';

export function RegisterContainer() {

    const [verifyEmailSent, setVerifyEmailSent] = useState(false);

    return (
        <>
        {
            localStorage.getItem('account')
                ? window.location = '/portal'
                : !verifyEmailSent
                    ? <Register setVerifyEmailSent={setVerifyEmailSent} />
                    : <RegisterSent />
        }
        </>
    );
}

