import React, { useState, useRef } from "react";

export function Photo() {
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInput = useRef(null);

  const handleTakePhoto = async () => {
    fileInput.current.click();
  };

  const handleFileChange = async (event) => {
    setLoading(true);

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);

    try {
      const response = await fetch("https://your-aws-chalice-endpoint.com/api/submit-photo", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit photo");
      }

      setPhoto(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" ref={fileInput} onChange={handleFileChange} style={{ display: "none" }} />
      {photo ? (
        <img src={photo} alt="Your photo" />
      ) : (
        <button onClick={handleTakePhoto} disabled={loading}>
          {loading ? "Loading..." : "Take Photo"}
        </button>
      )}
    </div>
  );
};

