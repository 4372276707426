import React, {useState, useEffect, useRef} from "react";
import LoadingIcons from 'react-loading-icons'
import { GiPerson } from "react-icons/gi";
import {AssignRole} from './modals/assign_role';

export function AdminUsers({gameInfo, account}) {

    const [adminInfo, setAdminInfo]= React.useState(false);
    const [users, setUsers]= React.useState(false);
    const [roles, setRoles]= React.useState(false);
    const [players, setPlayers]= React.useState(false);
    const [activePlayer, setActivePlayer]= React.useState(false);
    const [isAssignRoleModalOpen, setIsAssignRoleModalOpen] = React.useState(false);
    const [isUsersUpdated, setIsUsersUpdated] = React.useState(0);
    const adminFetchedRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        secondaryButton: {
            backgroundColor: 'green',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        gamesDiv: {
            backgroundColor: '#CCC',
            width: '100%',
            color: '#000',
        },
        icon: {
            fontSize: '80px',
        },
        userIcon: {
            fontSize: '50px',
        },
        adminIcon: {
            color: 'black',
            cursor: 'pointer',
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
            backgroundColor: 'white',
        },
        adminIconRole: {
            color: 'black',
            cursor: 'pointer',
            backgroundColor: 'lightgreen',
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
        },
    };

    useEffect(() => {
        if (adminFetchedRef.current) return;
        adminFetchedRef.current = true;
        if (!adminInfo) {
            getAdminInfo();
        }
    }, [isUsersUpdated]);

    const getAdminInfo = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/admin/users', {
            method: 'POST',
            body: JSON.stringify({
                account: account,
                game_id: gameInfo.uuid,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.status !== 200 || data.success !== true) {
            window.location = '/portal';
        } else {
            setUsers(getUsersJSX(data.response.users, data.response.players, getRolesObject(data.response.roles)));
            setRoles(getRolesObject(data.response.roles));
            console.log(getRolesObject(data.response.roles));
        }
    }

    function getRolesObject(game_roles) {
        const result = game_roles.reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        }, {});
        return result;
    }

    function getUsersJSX(users_response, players_response, roles_object) {
        if (Object.keys(users_response).length === 0) {
            console.log('yeeee');
            return 'No Users';
        }
        return (
            <div style={{display: 'flex'}}>
            {
                Object.keys(users_response).map((key,i)=>{
                    console.log(users_response[key]);
                    console.log('ye');
                    console.log(players_response);
                    console.log(players_response[key].role_id);
                    var isRoleSet = players_response[key].role_id;
                    return (<div>
                        <div style={isRoleSet ? styles.adminIconRole : styles.adminIcon}>
                            {users_response[key].first} {users_response[key].last}<br /><br />
                            {isRoleSet ? (<strong>{roles_object[isRoleSet].name}</strong>) : null}
                            <GiPerson style={styles.userIcon}/>
                        </div>
                        <div>
                            <button style={styles.secondaryButton} id={players_response[key].player_id} onClick={handleAssignRole}>Assign Role</button>
                        </div>
                    </div>);
                })
            }
            <br />
        </div>);
    }

    function handleAssignRole(e) {
        setActivePlayer(e.target.id);
        setIsAssignRoleModalOpen(true);
    }


    return (
    <div>
        <h2>Game Players</h2>
        {users ? users : 'nok' }
        {isAssignRoleModalOpen && activePlayer
            ? <AssignRole isModalOpen={isAssignRoleModalOpen} setIsModalOpen={setIsAssignRoleModalOpen} gameInfo={gameInfo} roles={roles} playerId={activePlayer} styles={styles} isUsersUpdated={isUsersUpdated} setIsUsersUpdated={setIsUsersUpdated} adminFetchedRef={adminFetchedRef}/>
            : null
        }
    </div>
    );
}
