import {Photo} from '../components/photo';

export function PhotoContainer() {


    return (
        <>
            <Photo/>
        </>
    );
}

