import React, {useState, useEffect, useRef} from "react";
import LoadingIcons from 'react-loading-icons'

export function WaitingRoom({gameInfo, playerInfo}) {

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        gamesDiv: {
            backgroundColor: '#CCC',
            width: '100%',
            color: '#000',
        },
        gameIcon: {
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
            backgroundColor: 'white',
        },
        icon: {
            fontSize: '80px',
        },
    };


    return (
    <div>
        <h2>Waiting Room</h2>
        <p>game has not started yet . . .</p>
        <LoadingIcons.Puff />
    </div>
    );
}
