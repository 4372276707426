import React, {useState} from "react";
import Modal from 'react-modal';

export function JoinGame({isModalOpen, setIsModalOpen, styles}) {

    const [joinGameId, setJoinGameId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function closeModal() {
        setIsModalOpen(false);
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'joinGameId') {
            setJoinGameId(value);
        }
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!joinGameId) {
            errorMessages.push('Please enter a Game ID');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            console.log('ye');
            return false;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/game/join-via-short-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                account: localStorage.getItem('account'),
                short_id: joinGameId,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error creating clue');
        } else {
            window.location.assign("/portal")
        }
    }

    return (
    <div className="App">
        <Modal
            isOpen={isModalOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal"
        >
            <button style={styles.close} onClick={closeModal}>x</button>
            <div style={styles.center}>
            <h2 style={styles.center}>Join a game</h2>
            <p>Enter the game ID or use your device to scan the "Join Game" QR code</p>
            <input style={styles.textInput} type="text" onChange={handleChange} name="joinGameId" />
            <button style={styles.button} onClick={handleSubmit}>join</button>
            </div>
      </Modal>
    </div>
    );
}
