import React, {useState} from "react";
import Modal from 'react-modal';
import { CgTranscript } from "react-icons/cg";

export function UpdateRole({isModalOpen, setIsModalOpen, gameInfo, roleId, roles, styles}) {

    console.log(roleId);
    console.log(roles);
    const [roleName, setRoleName] = useState(roles[roleId].name);
    const [createRoleAlibi, setCreateRoleAlibi] = useState(roles[roleId].alibi);
    const [createRoleQuestion, setCreateRoleQuestion] = useState(roles[roleId].secret_question);
    const [createRoleAnswer, setCreateRoleAnswer] = useState(roles[roleId].secret_answer);
    const [roleDBId, setRoleDBId] = useState(roles[roleId].id);
    const [errorMessage, setErrorMessage] = useState('');

    function closeModal() {
        setIsModalOpen(false);
    }

    //function handleChange(e) {
    //    let name = e.target.name;
    //    let value = e.target.value;
    //    if (name === 'createRoleName') {
    //        setCreateRoleName(value);
    //    }
   // }

    function handleRoleName(e) {
        setRoleName(e.target.value)
    }

    function handleRoleAlibi(e) {
        setCreateRoleAlibi(e.target.value)
    }

    function handleRoleQuestion(e) {
        setCreateRoleQuestion(e.target.value)
    }

    function handleRoleAnswer(e) {
        setCreateRoleAnswer(e.target.value)
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!roleName) {
            errorMessages.push('A Role Name is required');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        let bodyItems = {};
        if (createRoleAlibi) {
            bodyItems['role_alibi'] = createRoleAlibi;
        }
        if (createRoleQuestion) {
            bodyItems['role_question'] = createRoleQuestion;
        }
        if (createRoleAnswer) {
            bodyItems['role_answer'] = createRoleAnswer;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/game/update-role', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: roleName,
                account: localStorage.getItem('account'),
                game_id: gameInfo.uuid,
                role_id: roleDBId,
                ...bodyItems,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error updating role');
        } else {
           setIsModalOpen(false);
        }
    }

    return (
    <div className="App">
        <Modal
            isOpen={isModalOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal"
        >
            <button style={styles.close} onClick={closeModal}>x</button>
            <div style={styles.center}>
            <h2 style={styles.center}>Update Role</h2>
            <br />
            <label htmlFor='roleName' style={styles.label}>Name: </label>
            <input style={styles.textInput} type="text" onChange={handleRoleName} name="roleName" id="roleName" value={roleName}/><br />
            <br />
            <label htmlFor='createRoleAlibi' style={styles.label}>Alibi: </label>
            <textarea style={styles.textArea} type="texxtarea" onChange={handleRoleAlibi} name="createRoleAlibi" id="createRoleAlibi" >{createRoleAlibi}</textarea><br />
            <br />
            <label htmlFor='createRoleQuestion' style={styles.label}>Question: </label>
            <textarea style={styles.textArea} type="texxtarea" onChange={handleRoleQuestion} name="createRoleAlibi" id="createRoleQuestion" >{createRoleQuestion}</textarea><br />
            <br />
            <label htmlFor='createRoleAnswer' style={styles.label}>Answer: </label>
            <input style={styles.textInput} type="text" onChange={handleRoleAnswer} name="createRoleAnswer" id="createRoleAnswer" value={createRoleAnswer}/><br />
            <button style={styles.button} onClick={handleSubmit}>Update</button>

            { errorMessage ? (<p style={styles.errorMessage}>{errorMessage}</p>) : null }
            </div>
      </Modal>
    </div>
    );
}
