import React, {useState, useEffect, useRef} from "react";
import {JoinGame} from './modals/join_game';
import {CreateGame} from './modals/create_game';
import {
  useParams,
} from "react-router-dom";
import { GiGamepad } from "react-icons/gi";

export function Portal() {

    const [account, setAccount] = useState(localStorage.getItem('account'));
    const [first, setFirst] = useState(JSON.parse(localStorage.getItem('user'))['first']);
    const [createGameName, setCreateGameName] = useState('');
    const [isAdmin, setIsAdmin] = useState('');
    const [isJoinGameModalOpen, setIsJoinGameModalOpen] = React.useState(false);
    const [isCreateGameModalOpen, setIsCreateGameModalOpen] = React.useState(false);
    const [games, setGames] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let subtitle;
    const { gameUuid} = useParams();
    const gameInfoFetchedRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        gamesDiv: {
            backgroundColor: '#CCC',
            width: '100%',
            color: '#000',
        },
        gameIcon: {
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
            backgroundColor: 'white',
        },
        icon: {
            fontSize: '80px',
        },
    };

    function openCreateGameModal() {
        setIsCreateGameModalOpen(true);
    }

    function openJoinGameModal() {
        setIsJoinGameModalOpen(true);
    }

    function closejoinGameModal() {
        setIsJoinGameModalOpen(false);
    }

    function closeCreateGameModal() {
        setIsCreateGameModalOpen(false);
    }

    useEffect(() => {
        if (gameInfoFetchedRef.current) return;
        gameInfoFetchedRef.current = true;
        if (!games) {
            getGames();
        }
    }, []);

    const getGames = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/games/' + account, {
            method: 'GET',
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error logging in')
        } else {
            setIsAdmin(data.response.user.is_admin);
            setGames(getGamesJSX(data.response.games));
        }
    }

    function getGamesJSX(games_blob) {
        console.log(Object.keys(games_blob));
        if (Object.keys(games_blob).length === 0) {
            console.log('yeeee');
            return 'Create or Join a game';
        }
        return (<>
            {
                Object.keys(games_blob).map((key,i)=>{
                    return (
                        <a class="icon" href={"/game/" + key}>
                            <div style={styles.gameIcon}>
                                {games_blob[key]}<br />
                                <GiGamepad style={styles.icon}/>
                            </div>
                        </a>
                    )
                })
            }
            <br />
        </>);
    }

    return (
    <div className="App">
        <header className="App-header">
            <h1>Welcome, {first}</h1>
            <div className="registerForm">
                { isAdmin
                    ? <button style={styles.button} onClick={openCreateGameModal}>Create New Game</button>
                    : null
                }
                <button style={styles.button} onClick={openJoinGameModal}>Join a Game</button>
            </div>
        <br />
        <div style={styles.gamesDiv}>
            <h3>My Games</h3>
            { games ? games : 'Loading . . . ' }
        </div>
        </header>
        { isJoinGameModalOpen
            ? <JoinGame isModalOpen={isJoinGameModalOpen} setIsModalOpen={setIsJoinGameModalOpen} styles={styles} />
            : null
        }
        { isCreateGameModalOpen
            ? <CreateGame isModalOpen={isCreateGameModalOpen} setIsModalOpen={setIsCreateGameModalOpen} styles={styles} />
            : null
        }
    </div>
    );
}
