import React, {useState, useRef, useEffect} from "react";
import Modal from 'react-modal';
import {Profile} from './profile';
import {Tools} from './tools';
import {Clues} from './clues';
import {Answer} from './answer';
import {
  useParams,
} from "react-router-dom";

export function Dashboard({gameInfo, setGameInfo, playerInfo, setPlayerInfo, roleInfo, setRoleInfo}) {

    const [account, setAccount] = useState(localStorage.getItem('account'));
    const [name, setName] = useState(JSON.parse(localStorage.getItem('user'))['first']);
    const [roles, setRoles] = useState('');
    const [profileVisible, setProfileVisible] = useState('');
    const [toolsVisible, setToolsVisible] = useState('');
    const [answerVisible, setAnswerVisible] = useState('');
    const [cluesVisible, setCluesVisible] = useState(true);
    const [clueOrigins, setClueOrigins] = React.useState(false);
    const [clues, setClues] = React.useState(false);
    const [gameStarted, setGameStarted] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    console.log(gameInfo);


    let subtitle;
    const { gameUuid } = useParams();
    const dataFetchedRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
    };

    function handleOpenAnswer() {
        closeAllComponents();
        setAnswerVisible(true);
    }

    function handleOpenTools() {
        closeAllComponents();
        setToolsVisible(true);
    }

    function handleOpenClues() {
        closeAllComponents();
        setCluesVisible(true);
    }

    function handleOpenProfile() {
        closeAllComponents();
        setProfileVisible(true);
    }

    function closeAllComponents() {
       setProfileVisible(false);
       setAnswerVisible(false);
       setCluesVisible(false);
       setToolsVisible(false);
    }


    return (
    <div className="App">
        <header className="App-header">
            <h1>Dashboard: {gameInfo.name}</h1>
            <div className="registerForm">
                <button style={styles.button} onClick={handleOpenProfile}>Profile</button>
                <button style={styles.button} onClick={handleOpenClues}>Clues</button>
                <button style={styles.button} onClick={handleOpenAnswer}>Answer</button>
            </div>
            { profileVisible ? <Profile gameInfo={gameInfo} account={account} playerInfo={playerInfo} roleInfo={roleInfo} /> : null }
            { toolsVisible ? <Tools gameInfo={gameInfo} account={account} /> : null }
            { cluesVisible ? <Clues gameInfo={gameInfo} account={account} playerInfo={playerInfo} roleInfo={roleInfo} /> : null }
            { answerVisible ? <Answer gameInfo={gameInfo} account={account} playerInfo={playerInfo}/> : null }
        </header>
    </div>
    );
}
