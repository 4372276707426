import React, {useState} from "react";
import Modal from 'react-modal';
import { CgTranscript } from "react-icons/cg";

export function CreateRole({isModalOpen, setIsModalOpen, gameInfo, setRolesBody, styles, rolesBody}) {

    const [createRoleName, setCreateRoleName] = useState('');
    const [createRoleAlibi, setCreateRoleAlibi] = useState('');
    const [createRoleQuestion, setCreateRoleQuestion] = useState('');
    const [createRoleAnswer, setCreateRoleAnswer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function closeModal() {
        setIsModalOpen(false);
    }

    //function handleChange(e) {
    //    let name = e.target.name;
    //    let value = e.target.value;
    //    if (name === 'createRoleName') {
    //        setCreateRoleName(value);
    //    }
   // }

    function handleRoleName(e) {
        setCreateRoleName(e.target.value)
    }

    function handleRoleAlibi(e) {
        setCreateRoleAlibi(e.target.value)
    }

    function handleRoleQuestion(e) {
        setCreateRoleQuestion(e.target.value)
    }

    function handleRoleAnswer(e) {
        setCreateRoleAnswer(e.target.value)
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!createRoleName) {
            errorMessages.push('Please enter a name for your new role');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        let bodyItems = {};
        if (createRoleAlibi) {
            bodyItems['role_alibi'] = createRoleAlibi;
        }
        if (createRoleQuestion) {
            bodyItems['role_question'] = createRoleQuestion;
        }
        if (createRoleAnswer) {
            bodyItems['role_answer'] = createRoleAnswer;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/game/create-role', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: createRoleName,
                account: localStorage.getItem('account'),
                game_id: gameInfo.uuid,
                ...bodyItems,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error logging in');
        } else {
            setRolesBody(<>
                {rolesBody ? rolesBody : null}
                 <div style={styles.adminIcon}>
                     {createRoleName}<br /><br />
                     <CgTranscript style={styles.userIcon}/>
                 </div>
            </>);
           setIsModalOpen(false);
        }
    }

    return (
    <div className="App">
        <Modal
            isOpen={isModalOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal"
        >
            <button style={styles.close} onClick={closeModal}>x</button>
            <div style={styles.center}>
            <h2 style={styles.center}>Create a role</h2>
            <br />
            <label htmlFor='createRoleName' style={styles.label}>Name: </label>
            <input style={styles.textInput} type="text" onChange={handleRoleName} name="createRoleName" id="createRoleName" /><br />
            <br />
            <label htmlFor='createRoleAlibi' style={styles.label}>Alibi: </label>
            <textarea style={styles.textArea} type="texxtarea" onChange={handleRoleAlibi} name="createRoleAlibi" id="createRoleAlibi" ></textarea><br />
            <br />
            <label htmlFor='createRoleQuestion' style={styles.label}>Question: </label>
            <textarea style={styles.textArea} type="texxtarea" onChange={handleRoleQuestion} name="createRoleAlibi" id="createRoleQuestion" ></textarea><br />
            <br />
            <label htmlFor='createRoleAnswer' style={styles.label}>Answer: </label>
            <input style={styles.textInput} type="text" onChange={handleRoleAnswer} name="createRoleAnswer" id="createRoleAnswer" /><br />
            <button style={styles.button} onClick={handleSubmit}>Create</button>

            { errorMessage ? (<p style={styles.errorMessage}>{errorMessage}</p>) : null }
            </div>
      </Modal>
    </div>
    );
}
