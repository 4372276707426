import {Admin} from '../components/admin';

export function AdminContainer() {


    return (
        <>
            {
                localStorage.getItem('user') ? <Admin /> : window.location.replace('/')
            }
        </>
    );
}

