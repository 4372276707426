import React, {useState, useEffect, useRef} from "react";
import Modal from 'react-modal';
import {JoinGame} from './modals/join_game';
import {WaitingRoom} from './waiting_room';
import {Dashboard} from './dashboard';
import {CreateGame} from './modals/create_game';
import {
  useParams,
} from "react-router-dom";
import { GiGamepad } from "react-icons/gi";

export function Game() {

    const [account, setAccount] = useState(localStorage.getItem('account'));
    const [first, setFirst] = useState(JSON.parse(localStorage.getItem('user'))['first']);
    const [createGameName, setCreateGameName] = useState('');
    const [isJoinGameModalOpen, setIsJoinGameModalOpen] = React.useState(false);
    const [isCreateGameModalOpen, setIsCreateGameModalOpen] = React.useState(false);
    const [gameInfo, setGameInfo]= React.useState(false);
    const [playerInfo, setPlayerInfo]= React.useState(false);
    const [roleInfo, setRoleInfo]= React.useState(false);
    const [gameInfoRequested, setGameInfoRequested] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);
    const [websocketMessages, setWebsocketMessages] = useState(0);

    let subtitle;
    const { gameUuid } = useParams();
    const dataFetchedRef = useRef(false);
    const websocketRef = useRef(false);
    const {current: instance} = useRef({});
    const websocket = instance.websocket = instance.websocket || new WebSocket(process.env.REACT_APP_WEBSOCKET_HOST);

    useEffect(() => {
        function connectWebsocket() {
            websocket.onopen = (event) => {
                let subscription_payload = {
                    account: account,
                    game_id: gameInfo.id,
                    messageType: 'subscribe',
                }
                websocket.send(JSON.stringify(subscription_payload));
                setIsWebsocketConnected(true);
                console.log('connected to websocket');
            };
        }
        if (gameInfo) {
            connectWebsocket();
        }
    }, [gameInfo]);

    websocket.onmessage = function (event) {
        console.log(event);
        const json = JSON.parse(event.data);
        try {
            if (json) {
                setWebsocketMessages(websocketMessages + 1);
                console.log('message received from websocket');
            }
        } catch (err) {
            console.log(err);
        }
    };

    websocket.onclose = function (event) {
        setIsWebsocketConnected(false);
        console.log('websocket closed');
    }

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        gamesDiv: {
            backgroundColor: '#CCC',
            width: '100%',
            color: '#000',
        },
        gameIcon: {
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
            backgroundColor: 'white',
        },
        icon: {
            fontSize: '80px',
        },
    };

    function openCreateGameModal() {
        setIsCreateGameModalOpen(true);
    }

    function openJoinGameModal() {
        setIsJoinGameModalOpen(true);
    }

    function closejoinGameModal() {
        setIsJoinGameModalOpen(false);
    }

    function closeCreateGameModal() {
        setIsCreateGameModalOpen(false);
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (!gameInfo) {
            getGameInfo();
        }
    }, []);

    const getGameInfo = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/get-game-for-player/' + gameUuid, {
            method: 'POST',
            body: JSON.stringify({
                account: account,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        console.log(response.status);
        console.log(response.status !== 200);
        if (response.status !== 200 || data.success !== true) {
            window.location = '/portal';
        } else {
            setGameInfo(data.response.game_info);
            setPlayerInfo(data.response.player_info);
            setRoleInfo(data.response.role_info);
        }
    }

    return (
    <div className="App">
        { gameInfo.is_owner ? (<div><a href={"/admin/" + gameInfo.uuid}>Admin</a></div>) : null}
        <header className="App-header">
            {gameInfo && !gameInfo.is_initiated ? <WaitingRoom gameInfo={gameInfo} playerInfo={playerInfo} />: null}
            {gameInfo && gameInfo.is_initiated && !gameInfo.is_player_profile_enabled ? <WaitingRoom gameInfo={gameInfo} playerInfo={playerInfo} />: null}
            {gameInfo && gameInfo.is_initiated && gameInfo.is_player_profile_enabled ? <Dashboard gameInfo={gameInfo} setGameInfo={setGameInfo} playerInfo={playerInfo} setPlayerInfo={setPlayerInfo} roleInfo={roleInfo} setRoleInfo={setRoleInfo} />: null}
        </header>
    </div>
    );
}
