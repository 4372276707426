import {Portal} from '../components/portal';

export function PortalContainer() {


    return (
        <>
            {
                localStorage.getItem('user') ? <Portal /> : window.location.replace('/')
            }
        </>
    );
}

