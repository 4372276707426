import React, {useState} from "react";
import Modal from 'react-modal';
import { CgTranscript } from "react-icons/cg";

export function AssignVictim({isModalOpen, setIsModalOpen, gameInfo, roles, styles}) {

    const [victim, setVictim] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function closeModal() {
        setIsModalOpen(false);
    }

    function fieldsAreValid() {
        let errorMessages = []
        if (!victim) {
            errorMessages.push('Please select a victim');
        }
        if (errorMessages.length !== 0) {
            setErrorMessage(errorMessages.join('. '));
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const handleSubmit = async () => {
        if (!fieldsAreValid()) {
            return false;
        }
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/game/assign-victim', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                account: localStorage.getItem('account'),
                game_id: gameInfo.uuid,
                role_id: victim,
            }),
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error logging in');
        } else {
           setIsModalOpen(false);
        }
    }

    function handleSelect(e) {
        setVictim(e.target.value);
    }

    return (
    <div className="App">
        <Modal
            isOpen={isModalOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal"
        >
            <button style={styles.close} onClick={closeModal}>x</button>
            <div style={styles.center}>
            <h2>Assign the VICTIM to a role</h2>
            <div style={{display: 'inline-flex', margin: '6px' }}>
            <select id="victim" name="victim" onChange={handleSelect}>
                <option value='' selected></option>
            {
                Object.keys(roles).map((key,i)=>{
                    return (
                        <option value={key}>{roles[key]}</option>
                    );
                })
            }
            </select>
            </div>
            <button style={styles.button} onClick={handleSubmit}>Submit</button>
            { errorMessage ? (<p style={styles.errorMessage}>{errorMessage}</p>) : null }
            </div>
      </Modal>
    </div>
    );
}
