import React, {useState, useRef, useEffect} from "react";
import Modal from 'react-modal';
import {AdminUsers} from './admin_users';
import {AdminRoles} from './admin_roles';
import {AdminEvents} from './admin_events';
import {AdminClues} from './admin_clues';
import {
  useParams,
} from "react-router-dom";

export function Admin() {

    const [account, setAccount] = useState(localStorage.getItem('account'));
    const [name, setName] = useState(JSON.parse(localStorage.getItem('user'))['first']);
    const [roles, setRoles] = useState('');
    const [rolesVisible, setRolesVisible] = useState('');
    const [eventsVisible, setEventsVisible] = useState('');
    const [usersVisible, setUsersVisible] = useState('');
    const [cluesVisible, setCluesVisible] = useState('');
    const [clueOrigins, setClueOrigins] = React.useState(false);
    const [clues, setClues] = React.useState(false);
    const [gameStarted, setGameStarted] = React.useState(false);
    const [gameInfo, setGameInfo]= React.useState(false);
    const [playerInfo, setPlayerInfo]= React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);


    let subtitle;
    const { gameUuid } = useParams();
    const dataFetchedRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '200px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (!gameInfo) {
            getGameInfo();
        }
    }, []);

    const getGameInfo = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/get-game/' + gameUuid, {
            method: 'POST',
            body: JSON.stringify({
                account: account,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (data.success !== true) {
            setErrorMessage('Error logging in')
            window.location = '/portal';
        } else {
            if (!data.response.game_info.is_owner) {
                window.location = '/game/' + gameUuid;
                return;
            }
            setGameInfo(data.response.game_info);
            setPlayerInfo(data.response.player_info);
            setIsLoading(false);
        }
    };

    function handleOpenUsers() {
        closeAllComponents();
        setUsersVisible(true);
    }

    function handleOpenEvents() {
        closeAllComponents();
        setEventsVisible(true);
    }

    function handleOpenClues() {
        closeAllComponents();
        setCluesVisible(true);
    }

    function handleOpenRoles() {
        closeAllComponents();
        setRolesVisible(true);
    }

    function closeAllComponents() {
       setRolesVisible(false);
       setUsersVisible(false);
       setCluesVisible(false);
       setEventsVisible(false);
    }


    return (
    <div className="App">
        { gameInfo.is_owner ? (<div><a href={"/game/" + gameInfo.uuid}>Back to Game</a></div>) : null}
        { !isLoading ? (
        <header className="App-header">
            <h1>Admin: {gameInfo.name}</h1>
            <div className="registerForm">
                <button style={styles.button} onClick={handleOpenRoles}>Roles</button>
                <button style={styles.button} onClick={handleOpenUsers}>Players</button>
                <button style={styles.button} onClick={handleOpenClues}>Clues</button>
                <button style={styles.button} onClick={handleOpenEvents}>Events</button>
            </div>
            { usersVisible ? <AdminUsers gameInfo={gameInfo} account={account} /> : null }
            { rolesVisible ? <AdminRoles gameInfo={gameInfo} account={account} /> : null }
            { eventsVisible ? <AdminEvents gameInfo={gameInfo} account={account} /> : null }
            { cluesVisible ? <AdminClues gameInfo={gameInfo} account={account} /> : null }
        </header>)
        : null }
    </div>
    );
}
