import React, {useState, useEffect, useRef} from "react";
import LoadingIcons from 'react-loading-icons'
import { CgTranscript } from "react-icons/cg";
import {CreateRole} from './modals/create_role';
import {UpdateRole} from './modals/update_role';
import {AssignKiller} from './modals/assign_killer';
import {AssignVictim} from './modals/assign_victim';

export function Tools({gameInfo, account}) {

    const [adminInfo, setAdminInfo]= React.useState(false);
    const [users, setUsers]= React.useState(false);
    const [players, setPlayers]= React.useState(false);
    const [rolesObject, setRolesObject]= React.useState(false);
    const [rolesArray, setRolesArray]= React.useState(false);
    const [updateRole, setUpdateRole]= React.useState(false);
    const [rolesBody, setRolesBody]= React.useState(false);
    const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = React.useState(false);
    const [isAssignKillerModalOpen, setIsAssignKillerModalOpen] = React.useState(false);
    const [isAssignVictimModalOpen, setIsAssignVictimModalOpen] = React.useState(false);
    const [isUpdateRoleModalOpen, setIsUpdateRoleModalOpen] = React.useState(false);
    const adminRolesFetchedRef = useRef(false);

    const styles = {
        button: {
            backgroundColor: 'rgb(1 93 192)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        secondaryButton: {
            backgroundColor: 'green',
            color: '#fff',
            fontWeight: 'bold',
            padding: '12px 24px',
            margin: 5,
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#ffa500',
              boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
            },
        },
        errorMessage: {
            backgroundColor: 'rgb(250 143 143)',
            color: "FFF",
            borderRadius: '5px',
            padding: 5,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            h2: 'orange',
        },
        overlay: {
            backgroundColor: '#282c34',
        },
        center: {
            textAlign: 'center',
            margin: 'auto',
            color: '#000',
        },
        close: {
            top: '5px',
            right: '5px',
            position: 'absolute',
        },
        textInput: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '44px',
            padding: '0 12px',
            width: '300px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        textArea: {
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            height: '150px',
            padding: '0 12px',
            width: '300px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out',
            '&:focus': {
                borderColor: '#ff9000',
                boxShadow: '0px 0px 0px 2px rgba(255, 144, 0, 0.25)',
                outline: 'none',
            },
        },
        gamesDiv: {
            backgroundColor: '#CCC',
            width: '100%',
            color: '#000',
        },
        icon: {
            fontSize: '80px',
        },
        userIcon: {
            fontSize: '50px',
        },
        adminIcon: {
            color: 'black',
            cursor: 'pointer',
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '140px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
            backgroundColor: 'white',
        },
        adminIconRole: {
            color: 'black',
            cursor: 'pointer',
            backgroundColor: 'lightgreen',
            padding: '20px',
            margin: '20px',
            textAlign: 'center',
            height: '100px',
            display: 'inline-block',
            border: '1px solid #000',
            borderRadius: '5px',
        },
        label: {
            fontSize: '14px',
            color: '#333',
            display: 'block',
            marginBottom: '4px',
            fontWeight: 'bold',
        },
    };

    useEffect(() => {
        if (adminRolesFetchedRef.current) return;
        adminRolesFetchedRef.current = true;
        if (!adminInfo) {
            getAdminInfo();
        }
    }, []);

    const getAdminInfo = async () => {
        const response = await fetch(process.env.REACT_APP_MURDER_API + '/v1/admin/roles', {
            method: 'POST',
            body: JSON.stringify({
                account: account,
                game_id: gameInfo.uuid,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.status !== 200 || data.success !== true) {
            window.location = '/portal';
        } else {
            const result = data.response.roles.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            setRolesObject(result);
            setRolesArray(getRolesArray(data.response.users, data.response.players, data.response.roles));
            console.log(result);
            console.log('aaa');
            setRolesBody(getRolesJSX(data.response.users, data.response.players, data.response.roles));
        }
    }

    function getRolesArray(users_response, players_response, roles_response) {
        if (Object.keys(roles_response).length === 0) {
            return {};
        }
        var roleKiller = gameInfo.killer;
        var roleVictim = gameInfo.victim;
        var rolesArray = {}
        for (var role_response in roles_response) {
            rolesArray[roles_response[role_response].id] = roles_response[role_response].name
        }
        return rolesArray;
    }

    function getRolesJSX(users_response, players_response, roles_response) {
        if (Object.keys(roles_response).length === 0) {
            return false;
        }
        var roleKiller = gameInfo.killer;
        var roleVictim = gameInfo.victim;
        return (<div style={{display: 'flex', textAlign: 'center'}}>
            {
                Object.keys(roles_response).map((key,i)=>{
                    return (
                        <div style={styles.adminIcon} id={roles_response[key].id} onClick={handleEditRole}>
                            {roles_response[key].name}
                            {roleKiller === roles_response[key].id && roleVictim !== roles_response[key].id ? (<><br /><strong>Killer</strong><br /></>) : null}
                            {roleVictim == roles_response[key].id && roleKiller !== roles_response[key].id ? (<><br /><strong>Victim</strong><br /></>) : null}
                            {roleKiller !== roles_response[key].id && roleVictim !== roles_response[key].id ? (<><br /><br /></>) : null}
                            {roleKiller === roles_response[key].id && roleVictim === roles_response[key].id ? (<><br /><strong>Killer & Victim</strong><br /></>) : null}
                            <br /><CgTranscript style={styles.userIcon}/>
                        </div>
                    );
                })
            }
            <br />
        </div>);
    }

    function openCreateRoleModal() {
        setIsCreateRoleModalOpen(true);
    }

    function closeCreateRoleModal() {
        setIsCreateRoleModalOpen(false);
    }

    function openAssignKillerModal() {
        setIsAssignKillerModalOpen(true);
    }

    function closeAssignKillerModal() {
        setIsAssignKillerModalOpen(false);
    }

    function openAssignVictimModal() {
        setIsAssignVictimModalOpen(true);
    }

    function closeAssignVictimModal() {
        setIsAssignVictimModalOpen(false);
    }

    function handleEditRole(e) {
        setUpdateRole(e.target.id);
        console.log(e.target.id);
        console.log('boy howdy');
        setIsUpdateRoleModalOpen(true);
    }

    return (
    <div>
        <h2>Game Roles</h2>
        <div style={{display: 'inline-flex'}}>
        <button style={styles.secondaryButton} onClick={openCreateRoleModal}>Create Role</button><br /><br />
        <button style={styles.secondaryButton} onClick={openAssignVictimModal}>Assign Victim</button><br /><br />
        <button style={styles.secondaryButton} onClick={openAssignKillerModal}>Assign Killer</button><br /><br />
        </div>
        { rolesBody ? rolesBody : 'No Roles' }
        { isCreateRoleModalOpen
            ? <CreateRole isModalOpen={isCreateRoleModalOpen} setIsModalOpen={setIsCreateRoleModalOpen} gameInfo={gameInfo} setRolesBody={setRolesBody} styles={styles} rolesBody={rolesBody} />
            : null
        }
        { isUpdateRoleModalOpen && updateRole
            ? <UpdateRole isModalOpen={isUpdateRoleModalOpen} setIsModalOpen={setIsUpdateRoleModalOpen} gameInfo={gameInfo} roleId={updateRole} roles={rolesObject} styles={styles} />
            : null
        }
        { isAssignKillerModalOpen
            ? <AssignKiller isModalOpen={isAssignKillerModalOpen} setIsModalOpen={setIsAssignKillerModalOpen} gameInfo={gameInfo} roles={rolesArray} styles={styles} />
            : null
        }
        { isAssignVictimModalOpen
            ? <AssignVictim isModalOpen={isAssignVictimModalOpen} setIsModalOpen={setIsAssignVictimModalOpen} gameInfo={gameInfo} roles={rolesArray} styles={styles} />
            : null
        }
    </div>
    );
}
